const show = (message, color) => {
    const messageElt = document.getElementById('message');
    const messageTextElt = document.getElementById('message-text');
    messageTextElt.innerText = message;
    messageElt.classList.add(color);
    messageElt.classList.add('show');
    setTimeout(() => {
        messageElt.classList.remove('show');
        messageElt.classList.remove(color);
        messageTextElt.innerText = "";
    }, 6000);
};

const MessageService = {
    info: message => show(message, 'text-bg-primary'),
    success: message => show(message, 'text-bg-green'),
    warning: message => show(message, 'text-bg-warning'),
    error: message => show(message, 'text-bg-red')
  };
  
  export default MessageService;